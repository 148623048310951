import React, { ReactNode } from "react";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import ReactNumberTicker from "react-animate-number-ticker";
import "react-animate-number-ticker/dist/index.css";

interface CardProps {
  title: string;
  titleContent: string | number;
  width?: number;
  icon?: ReactNode;
  type: "small" | "large";
  useTicker?: boolean;
}

const CardComponent: React.FC<CardProps> = ({
  title,
  titleContent,
  width,
  icon,
  type,
  useTicker,
}) => {
  return (
    <Card
      variant="outlined"
      style={{ background: "rgba(21,23,43,.73" }}
      sx={{ width: width || 250, border: "none" }}
    >
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && icon}
          {!useTicker && (
            <Typography
              level="h1"
              sx={{ fontSize: type === "large" ? 45 : 20, color: "#fff" }}
            >
              {titleContent}
            </Typography>
          )}
          {useTicker && (
            <ReactNumberTicker
              className="custom-ticker"
              number={titleContent}
            />
          )}
        </div>
        <Typography
          level="title-lg"
          sx={{ fontSize: type === "large" ? 18 : 12, color: "#fff" }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
