import Card from '@mui/joy/Card';
import Clock from 'react-live-clock';

const ClockComponent = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <Card variant="outlined" sx={{ width: 360, height: 90, border: 'none', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'row' }} style={{ background: 'rgba(21,23,43,.73' }}>
      <div className="live-indicator">
        <span className="live-text">LIVE</span>
      </div>
      <div style={{ fontSize: 30, fontWeight: 'bold', color: '#fff' }}>
        <Clock format={'HH:mm:ss A'} ticking={true} blinking={true} timezone={timezone} />
      </div>
    </Card>
  );
};

export default ClockComponent;
