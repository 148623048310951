/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import Globe from "react-globe.gl";
import { useQuery } from "react-query";
import { getMockTransactions, getTransactions } from "../data/getTransactions";
import countries from "../data/json/countries.json";
import { Arc } from "../types/arc";
import { Ring } from "../types/ring";
import { Label } from "../types/label";
import currency from "../data/json/curreny.json";
import * as c from "../constants";
import { WorldDataFeature } from "../types/worldData";
import usePageRefresh from "../hooks/usePageRefresh";
import CardComponent from "./card";
import ClockComponent from "./clock";
import { getColour } from "../utils/getColor";

export const WorldGlobe = () => {
  const [transactionCount, setTransactionCount] = useState<number>(0);
  const [arcsData, setArcsData] = useState<Arc[]>([]);
  const [ringsData, setRingsData] = useState<Ring[]>([]);
  const [labelData, setLabelData] = useState<Label[]>([]);
  const GLOBE_TYPE = "1";
  const GLOBE_SPEED = 1.2;
  const [jsonHelper, setJsonHelper] = useState<number>(0);
  const globeEl = useRef<any>();
  const time = useRef<number>();
  const urlParams = new URLSearchParams(window.location.search);
  const numbers = urlParams.get("hn");
  const mockData = urlParams.get("md");
  const { data, refetch } = useQuery(
    "transactions",
    mockData && mockData === "true" ? getMockTransactions : getTransactions,
    {}
  );
  usePageRefresh();
  const countRef = useRef(transactionCount);

  console.log(transactionCount, "COUNT");

  // Keep ref updated with the latest transactionCount
  useEffect(() => {
    countRef.current = transactionCount;
  }, [transactionCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      const n = new Date();
      const perSecond = transactionCount / n.getHours() / 3600;
      console.error(perSecond, "xxx");
      // Example: If you want to update state, do it safely here
      setTransactionCount(countRef.current + Math.floor(perSecond * 5));
    }, 5000);

    return () => clearInterval(interval); // Cleanup
  }, [countRef.current]);

  useEffect(() => {
    const globe = globeEl.current as any;
    if (globe) {
      setTimeout(() => {
        const directionalLight = globe
          .scene()
          .children.find((obj3d: any) => obj3d.type === "DirectionalLight");
        if (directionalLight) {
          directionalLight.intensity = 0.5;
        }
      });
      globe.pointOfView(c.MAP_CENTER);
      globe.controls().autoRotate = true;
      globe.controls().autoRotateSpeed = GLOBE_SPEED;
      setRingsData([]);
      setArcsData([]);
      setLabelData([]);
    }
  }, [GLOBE_TYPE, GLOBE_SPEED]);
  useEffect(() => {
    let interval: NodeJS.Timer;
    let countt = 0;

    if (data) {
      try {
        time.current = 1;
        const max = data.transactions[0].secondsOffsetFromQueryTime || 0;
        setTransactionCount(data.totalSinceMidnight[0].totalSinceMidnight);
        interval = setInterval(function () {
          if (time.current! <= max) {
            try {
              const t = data.transactions.filter(
                (tr) => tr.secondsOffsetFromQueryTime === time.current
              );
              if (t && t.length > 0) {
                t.forEach((k) => {
                  countt = countt + 1;

                  const toISO = k.toCountryISO as keyof typeof countries;
                  const fromISO = k.fromCountryISO as keyof typeof countries;
                  const start = countries[fromISO];
                  const end = countries[toISO];
                  if (start && end) {
                    emitArc(
                      {
                        startLat: start.latitude,
                        startLng: start.longitude,
                        endLat: end.latitude,
                        endLng: end.longitude,
                        type: k.distributorCategory
                          .toLowerCase()
                          .replace(" ", ""),
                      },
                      {
                        amount: k.sendAmount,
                        currency: k.sendCurrencyISO,
                        from: k.fromCountryISO,
                        destinationCode: k.destinationCode,
                        to: k.toCountryISO,
                        lat: end.latitude,
                        lng: end.longitude,
                        type: k.distributorCategory,
                        productType: k.productType,
                      }
                    );
                  } else {
                    console.log("missing country", fromISO, toISO);
                  }
                });
              }
              time.current = time.current! + 1;
            } catch (error) {
              console.log("data error", error, data);
            }
          } else {
            clearInterval(interval);
            refetch();
            setJsonHelper(jsonHelper + 1); // force refresh for json file
          }
        }, 1000);
      } catch (error) {
        console.log("transaction error", error);
      }
    } else {
      console.log("no data");
    }
    return () => {
      clearInterval(interval);
    };
  }, [data, jsonHelper]);

  const emitArc = (arc: Arc, label: Label) => {
    setArcsData((curArcsData) => [...curArcsData, arc]);
    setTimeout(
      () => setArcsData((curArcsData) => curArcsData.filter((d) => d !== arc)),
      c.ARC_TIME
    );
    // add and remove target rings
    setTimeout(() => {
      const targetRing = { lat: arc.endLat, lng: arc.endLng };
      setRingsData((curRingsData) => [...curRingsData, targetRing]);
      setLabelData((curLblData) => [...curLblData, label]);

      setTimeout(() => {
        setRingsData((curRingsData) =>
          curRingsData.filter((r) => r !== targetRing)
        );
        setLabelData((curLblData) => curLblData.filter((r) => r !== label));
      }, c.FLIGHT_TIME * c.ARC_REL_LEN);
    }, c.FLIGHT_TIME);
  };

  const handlePoligonColor = useCallback((k: any) => {
    const data = k as WorldDataFeature;
    return data.properties.colour;
  }, []);

  const handleHtmlElement = (d: object) => {
    const data = d as Label;
    const fromISO = data.from as keyof typeof countries;
    const toISO = data.to as keyof typeof countries;
    const cur = data.currency as keyof typeof currency;
    const from = countries[fromISO];
    const to = countries[toISO];
    const el = document.createElement("div");
    const cursym = currency[cur] ? currency[cur].symbol : cur;
    const isDomestic = from.country === to.country;
    const isVoucher = data.productType === "Voucher";
    const distributorCode = data.destinationCode || "";
    const providerCode = distributorCode.substring(
      0,
      distributorCode.length - 2
    );
    const repoLink = `https://imagerepo.ding.com/logo/${providerCode}.png?height=70`;

    if (isVoucher && data.destinationCode && data.destinationCode !== "") {
      el.innerHTML = `
                    <div>
                    <img style='border-radius: 16px' src=${repoLink} alt="" height="100%" width="100%" />
                    </div>`;
      data.type = "voucher";
    } else if (isDomestic) {
      el.innerHTML = `<div class='top'>${data.type} |  <span class='cur'>${cursym}${data.amount}</span></div>
                    <hr/>
                    <div class='bottom'><span class='country'>${from.country}</span> <span></span> <span class='country'>domestic</span></div>`;
    } else {
      el.innerHTML = `<div class='top'>${data.type} |  <span class='cur'>${cursym}${data.amount}</span></div>
                    <hr/>
                    <div class='bottom'><span class='country'>${from.country}</span> <span>to</span> <span class='country'>${to.country}</span></div>`;
    }

    el.className = data.type.toLowerCase().replace(/\s/g, "");
    console.log(el.className);
    return el;
  };

  const handleArcColour = useCallback((k) => {
    const l = [getColour(k.type, true), getColour(k.type)];
    return l;
  }, []);

  const handleRingColour = useCallback((k) => {
    return `#73f87e`;
  }, []);

  const getTransactionsPerMinute = () => {
    if (!transactionCount) return "";
    const n = new Date();
    return (transactionCount / n.getHours() / 60).toFixed(2);
  };

  const getTransactionsPerSecond = () => {
    if (!transactionCount) return "";
    const n = new Date();
    return (transactionCount / n.getHours() / 3600).toFixed(2);
  };

  const isNightTime = () => {
    const now = new Date();
    const hours = now.getHours();
    return hours < 6 || hours > 18;
  };

  const getTodayWithMonthName = () => {
    const today = new Date();
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    return today.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="logo-wrap">
        <img src="./img/logo.png" alt="Logo" className="logo" />
        <div
          style={{
            marginLeft: 45,
            marginTop: -10,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <span className="logo-text">A little goes a long way</span>
          <svg
            data-testid="asterisk-icon"
            width="16"
            height="16"
            viewBox="0 0 32 32"
          >
            <path
              d="M1252.973 15.036l-1.658-6.188c-.112-.42-.544-.668-.963-.556l-7.71 2.066 2.066-7.71c.112-.42-.137-.85-.556-.963l-6.188-1.658c-.42-.112-.85.137-.963.556l-2.066 7.71-5.644-5.644c-.307-.307-.805-.307-1.112 0l-4.53 4.53c-.307.307-.307.805 0 1.112l5.644 5.644-7.71 2.066c-.42.112-.668.544-.556.963l1.658 6.188c.112.42.544.668.963.556l7.71-2.066-2.066 7.71c-.112.42.137.85.556.963l6.188 1.658c.42.112.85-.136.963-.556l2.066-7.71 5.644 5.644c.307.307.805.307 1.112 0l4.53-4.53c.307-.307.307-.805 0-1.112l-5.644-5.644 7.71-2.066c.42-.112.668-.544.556-.963"
              transform="translate(-1221)"
              fill="#D600C4"
            ></path>
          </svg>
        </div>
      </div>
      {numbers !== "true" && (
        <>
          <div
            style={{ position: "absolute", right: 0, zIndex: 2, bottom: 226 }}
          >
            <CardComponent
              useTicker
              type="large"
              icon={
                <svg
                  style={{ marginRight: 5 }}
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1252.973 15.036l-1.658-6.188c-.112-.42-.544-.668-.963-.556l-7.71 2.066 2.066-7.71c.112-.42-.137-.85-.556-.963l-6.188-1.658c-.42-.112-.85.137-.963.556l-2.066 7.71-5.644-5.644c-.307-.307-.805-.307-1.112 0l-4.53 4.53c-.307.307-.307.805 0 1.112l5.644 5.644-7.71 2.066c-.42.112-.668.544-.556.963l1.658 6.188c.112.42.544.668.963.556l7.71-2.066-2.066 7.71c-.112.42.137.85.556.963l6.188 1.658c.42.112.85-.136.963-.556l2.066-7.71 5.644 5.644c.307.307.805.307 1.112 0l4.53-4.53c.307-.307.307-.805 0-1.112l-5.644-5.644 7.71-2.066c.42-.112.668-.544.556-.963"
                    transform="translate(-1221)"
                    fill="#D600C4"
                  />
                </svg>
              }
              title="Transactions today"
              titleContent={transactionCount.toLocaleString()}
            />
          </div>
          <div style={{ position: "absolute", right: 0, zIndex: 2, bottom: 2 }}>
            <CardComponent
              type="small"
              width={310}
              title=""
              titleContent={getTodayWithMonthName()}
            />
          </div>
          <div
            style={{ position: "absolute", right: 0, zIndex: 2, bottom: 59 }}
          >
            <ClockComponent />
          </div>
          <div
            style={{ position: "absolute", right: 0, zIndex: 2, bottom: 153 }}
          >
            <CardComponent
              type="small"
              width={150}
              icon={
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 64 64"
                  transform="rotate(180)"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M0 0H64V64H0z"
                      transform="translate(-323 -2082) translate(207 2050) translate(116 32)"
                    ></path>
                    <g>
                      <path
                        d="M0 0H64V64H0z"
                        transform="translate(-323 -2082) translate(207 2050) translate(116 32)"
                      ></path>
                      <g>
                        <path
                          fill="#CBF200"
                          d="M42.667 53.333c0 2.934-2.4 5.334-5.334 5.334h-32C2.4 58.667 0 56.267 0 53.333v-48C0 2.4 2.4 0 5.333 0h32c2.934 0 5.334 2.4 5.334 5.333"
                          transform="translate(-323 -2082) translate(207 2050) translate(116 32) translate(10.667 2.667)"
                        ></path>
                        <path
                          fill="#004A59"
                          d="M19.467 15.2c1.066-1.067 2.666-1.067 3.733 0l9.6 9.6c1.6 1.6.533 4.533-1.867 4.533h-5.6v13.334c0 2.133-1.866 4-4 4-2.133 0-4-1.867-4-4V29.333H12c-2.4 0-3.467-2.933-1.867-4.533l9.334-9.6zM42.667 5.333C42.667 2.4 40.267 0 37.333 0h-32C2.4 0 0 2.4 0 5.333V8h42.667M42.667 50.667H0v2.666c0 2.934 2.4 5.334 5.333 5.334h32c2.934 0 5.334-2.4 5.334-5.334"
                          transform="translate(-323 -2082) translate(207 2050) translate(116 32) translate(10.667 2.667)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              }
              title="Per second"
              titleContent={getTransactionsPerSecond()}
            />
          </div>
          <div
            style={{ position: "absolute", right: 155, zIndex: 2, bottom: 153 }}
          >
            <CardComponent
              type="small"
              width={140}
              icon={
                <svg
                  style={{ marginRight: 5 }}
                  width={20}
                  height={18}
                  viewBox="0 0 18 16"
                >
                  <g fill={"none"} fillRule="evenodd">
                    <g stroke={"red"} strokeWidth="1.5">
                      <g>
                        <g>
                          <path
                            d="M110.625 10.249c1.727-1.457 3.838-1.638 5.521-.476 1.62 1.12 2.334 3.355 1.51 5.41-.422 1.046-1.093 2.067-1.88 2.87-1.66 1.69-3.452 3.248-5.239 4.802-.22.191-.88.194-1.098.004-1.808-1.576-3.592-3.185-5.32-4.851-1.332-1.286-2.201-2.835-2.113-4.794.152-3.383 3.67-5.307 6.526-3.552.51.313.964.72 1.48 1.111.207-.177.408-.352.613-.524z"
                            transform="translate(-308 -314) translate(0 218) translate(207 88)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              }
              title="Per minute"
              titleContent={getTransactionsPerMinute()}
            />
          </div>
        </>
      )}
      <Globe
        showAtmosphere
        globeImageUrl={
          isNightTime() ? "./img/earth_night.jpg" : "./img/earth_8k.jpg"
        }
        backgroundImageUrl="./img/night-sky.png"
        atmosphereColor="white"
        hexPolygonResolution={3}
        hexPolygonMargin={0.2}
        hexPolygonColor={handlePoligonColor}
        atmosphereAltitude={0.1}
        htmlElementsData={labelData}
        htmlElement={handleHtmlElement}
        htmlTransitionDuration={0}
        ref={useCallback<any>(globeEl, [])}
        arcsData={arcsData}
        arcColor={handleArcColour}
        arcDashLength={c.ARC_REL_LEN}
        arcDashGap={2}
        arcDashInitialGap={1}
        arcDashAnimateTime={c.FLIGHT_TIME}
        arcsTransitionDuration={0}
        ringsData={ringsData}
        arcStroke={2.3}
        ringColor={handleRingColour}
        ringMaxRadius={c.RINGS_RADIUS_SIZE}
        ringPropagationSpeed={c.RING_PROPAGATION_SPEED}
        ringRepeatPeriod={(c.FLIGHT_TIME * c.ARC_REL_LEN) / c.NUM_RINGS}
      />
    </>
  );
};
